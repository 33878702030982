import { Scene, GameObjects } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';
import { maskHelper } from '../helpers/maskHelper';
import { questionHelper } from '../helpers/questionHelper';

import { BubbleType, TextBubble } from '../gameobjects/TextBubble';
import { QuestionDisplay } from '../gameobjects/QuestionDisplay';
import { MuteButton } from '../gameobjects/MuteButton';

export class TextingGame extends Scene {
    
    camera: Phaser.Cameras.Scene2D.Camera;

    backgroundMusic: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;

    background: GameObjects.Image;
    maskRect: Phaser.Geom.Rectangle;
    maskGraphics: GameObjects.Graphics;
    backgroundMask: Phaser.Display.Masks.GeometryMask;

    phone: GameObjects.Image;
    screenRect: Phaser.Geom.Rectangle;
    screenMask: GameObjects.Image;
    textMask: Phaser.Display.Masks.BitmapMask;

    badGuy: GameObjects.Image;
    badGuyBackground: GameObjects.Image;
    badGuyScreenMask: GameObjects.Image;
    badGuyMask: Phaser.Display.Masks.BitmapMask;

    phonePositionInit: Phaser.Geom.Point;
    phonePosition: Phaser.Geom.Point;
    phoneAngleInit: number;
    phoneAngle: number;
    yAxisAngle: number;
    xAxisAngle: number;
    xAxisAngleOther: number;

    textBubbles: TextBubble[];
    bubblePositionInit: Phaser.Geom.Point;
    bubblePosition: Phaser.Geom.Point; // the y-axis point from which the actual bubble position is derived
    bubblePadding: number;
    maxDistance: number;

    questionBackground: GameObjects.Image;
    questionDisplay: QuestionDisplay;

    muteButton: MuteButton;

    //correctAnimation: GameObjects.Sprite;
    //correctStarburst: GameObjects.Image;
    incorrectAnimation: GameObjects.Sprite;
    
    report: GameObjects.Image;
    block: GameObjects.Image;
    plusOne: GameObjects.Image;
    plusOnePositionY: number;

    badGuyFocus: GameObjects.Image;

    constructor () {
        super('TextingGame');
    }

    init(data: any) {

        //console.log('Loading scenario: ' + data.scenario);
        questionHelper.loadData(this, data.scenario);

        this.camera = this.cameras.main;
        this.camera.setBackgroundColor(0x000000);

        this.phonePositionInit = new Phaser.Geom.Point(131, 1250);
        this.phonePosition = new Phaser.Geom.Point(281, 550);
        this.phoneAngleInit = -60;
        this.phoneAngle = -5;
        this.yAxisAngle = -95;
        this.xAxisAngle = -5;
        this.xAxisAngleOther = -185;

        this.textBubbles = [];
        this.bubblePositionInit = this.bubblePosition = this.getYAxisPositionFrom(this.phonePosition, 360);
        this.bubblePadding = 8;
        this.maxDistance = 500;

    }

    create() {

        // background music

        this.backgroundMusic = this.sound.add('BackgroundMusic');
        this.backgroundMusic.setLoop(true);
        this.backgroundMusic.setVolume(0);
        this.backgroundMusic.play();

        // background and mask

        this.maskRect = Phaser.Geom.Rectangle.Clone(maskHelper.maskActiveRect);
        this.maskGraphics = new GameObjects.Graphics(this);
        maskHelper.redrawMask(this.maskGraphics, this.maskRect);
        this.backgroundMask = new Phaser.Display.Masks.GeometryMask(this, this.maskGraphics);

        this.background = this.add.image(672, 384, assetHelper.texture, 'Background.png');
        this.background.mask = this.backgroundMask;

        // phone

        this.phone = new GameObjects.Image(this, this.phonePositionInit.x, this.phonePositionInit.y, assetHelper.texture, 'Phone.png');
        this.phone.angle = this.phoneAngleInit;

        const screenMaskPosition = this.getYAxisPositionFrom(this.phonePosition, 358);
        this.screenMask = new GameObjects.Image(this, screenMaskPosition.x, screenMaskPosition.y, assetHelper.texture, 'ScreenMask.png');
        this.screenMask.setOrigin(0.5, 0);
        this.screenMask.angle = this.phoneAngle;

        this.textMask = new Phaser.Display.Masks.BitmapMask(this, this.screenMask);

        // bad guy

        this.badGuy = new GameObjects.Image(this, this.phonePosition.x, this.phonePosition.y - 10, assetHelper.texture, 'BadGuy.png');
        this.badGuy.angle = this.phoneAngle;
        this.badGuy.alpha = 0;

        const backgroundPosition = this.getYAxisPositionFrom(this.phonePosition, 363);
        this.badGuyBackground = new GameObjects.Image(this, backgroundPosition.x, backgroundPosition.y, assetHelper.texture, 'ScreenMask.png');
        this.badGuyBackground.setOrigin(0.5, 0);
        this.badGuyBackground.angle = this.phoneAngle;

        const badGuyMaskPosition = this.getYAxisPositionFrom(this.phonePosition, 360);
        this.badGuyScreenMask = new GameObjects.Image(this, badGuyMaskPosition.x, badGuyMaskPosition.y, assetHelper.texture, 'ScreenMask.png');
        this.badGuyScreenMask.setOrigin(0.5, 0);
        this.badGuyScreenMask.angle = this.phoneAngle;
        this.badGuyScreenMask.scaleY = 0;

        this.badGuyMask = new Phaser.Display.Masks.BitmapMask(this, this.badGuyScreenMask);
        this.badGuy.mask = this.badGuyMask;
        this.badGuyBackground.mask = this.badGuyMask;

        // questions

        this.questionBackground = new GameObjects.Image(this, 885, 384, assetHelper.texture, 'QuestionBackground.png');
        this.questionBackground.alpha = 0;

        this.questionDisplay = new QuestionDisplay(this, 471, 50, (choice: string) => this.handleChoiceSelected(choice));

        this.muteButton = new MuteButton(this, 1294, 50, (isMuted: boolean) => isMuted ? this.backgroundMusic.pause() : this.backgroundMusic.resume());

        // overlay animations
        /*
        this.correctAnimation = new GameObjects.Sprite(this, 490, this.questionDisplay.yPositions[0] - 90, assetHelper.texture, 'CorrectMark.png');
        this.correctAnimation.visible = false;
        this.correctStarburst = new GameObjects.Image(this, 490, this.questionDisplay.yPositions[0] - 75, assetHelper.texture, 'Starburst.png');
        this.correctStarburst.visible = false;
        */
        this.incorrectAnimation = new GameObjects.Sprite(this, 520, this.questionDisplay.yPositions[0] - 90, assetHelper.texture, 'IncorrectMark.png');
        this.incorrectAnimation.visible = false;

        //this.report = new GameObjects.Image(this, 765, 484, assetHelper.texture, 'Report.png');
        this.report = new GameObjects.Image(this, this.questionBackground.x, this.questionBackground.y, assetHelper.texture, 'Report.png');
        this.report.visible = false;

        this.block = new GameObjects.Image(this, this.phonePosition.x - 10, this.phonePosition.y - 50, assetHelper.texture, 'Block.png');
        this.block.visible = false;

        //this.plusOne = new GameObjects.Image(this, 885, 584, assetHelper.texture, 'PlusOne.png');
        this.plusOnePositionY = this.questionDisplay.yPositions[0] - 90;
        this.plusOne = new GameObjects.Image(this, 1240, this.plusOnePositionY, assetHelper.texture, 'PlusOneGreen.png');
        this.plusOne.visible = false;

        // effects

        this.badGuyFocus = this.add.image(672, 384, assetHelper.texture, 'BadGuyFocus.png');
        this.badGuyFocus.visible = false;

        // add game objects
        this.add.existing(this.questionBackground);
        this.add.existing(this.phone);
        this.add.existing(this.badGuyBackground);
        this.add.existing(this.badGuy);
        this.add.existing(this.questionDisplay);
        this.add.existing(this.muteButton);
        //this.add.existing(this.correctStarburst);
        //this.add.existing(this.correctAnimation);
        this.add.existing(this.incorrectAnimation);
        this.add.existing(this.report);
        this.add.existing(this.block);
        this.add.existing(this.plusOne);
        this.add.existing(this.badGuyFocus);

        // set depth order for overlayed ui elements
        this.badGuyFocus.setDepth(1999);
        //this.correctAnimation.setDepth(1998);
        //this.correctStarburst.setDepth(1997);
        this.incorrectAnimation.setDepth(1996);
        this.report.setDepth(1995);
        this.block.setDepth(1994);
        this.plusOne.setDepth(1993);
        this.questionDisplay.setDepth(1992);

        // play initial phone tween
        this.tweens.add({
            targets: this.phone,
            x: { from: this.phonePositionInit.x, to: this.phonePosition.x },
            y: { from: this.phonePositionInit.y, to: this.phonePosition.y },
            angle: { from: this.phoneAngleInit, to: this.phoneAngle },
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400,
            delay: 400
        });

        // tween in question background
        this.tweens.add({
            targets: this.questionBackground,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 250,
            delay: 900,
            onComplete: () => this.loadNextQuestion()
        });

        // tween in volume
        this.tweens.add({
            targets: this.backgroundMusic,
            volume: 0.2,
            ease: Phaser.Math.Easing.Linear,
            duration: 5000,
            delay: 500
        });

    }

    private loadNextQuestion(): void {

        if (questionHelper.loadNextQuestion()) {

            // update, fade in question display
            this.questionDisplay.setQuestion(
                questionHelper.question,
                questionHelper.choices
            );

            this.time.delayedCall(500, () => this.addPreTexts());

        } else {

            this.time.delayedCall(1000, () => this.transitionToResultsScreen());

        }

    }

    private addPreTexts(): void {

        const textDelay = this.addTexts(questionHelper.preTexts);

        this.time.delayedCall(textDelay + 500, () => this.questionDisplay.fadeIn(this));

    }

    private handleChoiceSelected(choice: string): void {

        this.questionDisplay.enableButtons(false);

        if (questionHelper.checkQuestion(choice)) {

            // correct
            this.questionDisplay.fadeOutButtons(this, [choice]);

            this.time.delayedCall(300, () => this.questionDisplay.animateCorrect(this, questionHelper.correctChoice, choice));

            //this.time.delayedCall(1200, () => this.showCorrectAnimation());

            //this.time.delayedCall(2800, () => this.showPlusOneAnimation());
            this.time.delayedCall(1200, () => this.showPlusOneAnimation());

            if (questionHelper.correctAnimations.includes('block')) {
                //this.time.delayedCall(1400, () => this.showBlockAnimation());
                this.time.delayedCall(2400, () => this.showBlockAnimation());
            }
            if (questionHelper.correctAnimations.includes('report')) {
                //this.time.delayedCall(2000, () => this.showReportAnimation());
                this.time.delayedCall(1600, () => this.showReportAnimation());
            }

        } else {

            // incorrect
            this.questionDisplay.fadeOutButtons(this, [choice, questionHelper.correctChoice]);

            this.time.delayedCall(300, () => this.questionDisplay.animateCorrect(this, questionHelper.correctChoice, choice));

            this.time.delayedCall(100, () => this.showIncorrectAnimation(choice));

            this.time.delayedCall(2800, () => this.revealBadGuy(questionHelper.wrongAnswers));

        }

        this.time.delayedCall(4200, () => this.addChoiceTexts(choice));
        
    }

    private addChoiceTexts(choice: string): void {

        let textDelay = 0;
        if (questionHelper.choiceTexts != null && !questionHelper.isGameOver()) {
            const texts = questionHelper.choiceTexts[choice];
            textDelay = this.addTexts(texts);
        }

        this.time.delayedCall(textDelay + 600, () => this.completeQuestionSequence());

    }

    private completeQuestionSequence(): void {

        this.questionDisplay.fadeOutQuestionText(this);
        this.questionDisplay.fadeOutButtons(this, []);

        if (!questionHelper.isGameOver()) {
            this.time.delayedCall(600, () => this.loadNextQuestion());
        }

    }

    /*
    private showCorrectAnimation(): void {
        
        this.correctAnimation.scale = 1;
        this.correctAnimation.alpha = 0.9;
        this.correctAnimation.visible = true;
        this.correctAnimation.anims.play('CorrectAnimation');
        this.tweens.add({
            targets: this.correctAnimation,
            scale: 1.1,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 600,
            delay: 300
        });
        this.tweens.add({
            targets: this.correctAnimation,
            scale: 0.5,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.In,
            duration: 400,
            delay: 900,
            onComplete: () => this.correctAnimation.visible = false
        });

        this.correctStarburst.scale = 0.6;
        this.correctStarburst.alpha = 0;
        this.correctStarburst.angle = 0;
        this.correctStarburst.visible = true;
        this.tweens.add({
            targets: this.correctStarburst,
            scale: 0.9,
            alpha: 0.2,
            angle: 27,
            ease: Phaser.Math.Easing.Linear,
            duration: 900
        });
        this.tweens.add({
            targets: this.correctStarburst,
            scale: 0.6,
            alpha: 0.1,
            angle: 36,
            ease: Phaser.Math.Easing.Linear,
            duration: 300,
            delay: 900
        });
        this.tweens.add({
            targets: this.correctStarburst,
            alpha: 0,
            angle: 39,
            ease: Phaser.Math.Easing.Linear,
            duration: 100,
            delay: 1200,
            onComplete: () => this.correctStarburst.visible = false
        });

    }
    */

    private showIncorrectAnimation(choice: string): void {

        const index = this.questionDisplay.choices.indexOf(choice);
        
        this.incorrectAnimation.y = this.questionDisplay.yPositions[index] + 80;
        this.incorrectAnimation.scale = 2;
        this.incorrectAnimation.alpha = 0.8;
        this.incorrectAnimation.visible = true;
        this.incorrectAnimation.anims.play('IncorrectAnimation');
        this.tweens.add({
            targets: this.incorrectAnimation,
            scale: 0.6,
            alpha: 1,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 600
        });
        this.tweens.add({
            targets: this.incorrectAnimation,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400,
            delay: 2400,
            onComplete: () => this.incorrectAnimation.visible = false
        });

        this.time.delayedCall(2400, () => this.questionDisplay.fadeOutButtons(this, [questionHelper.correctChoice]));

        //this.time.delayedCall(2400, () => this.questionDisplay.fadeOutCorrectMark(this, questionHelper.correctChoice));

    }

    private showReportAnimation(): void {

        this.report.alpha = 0;
        this.report.angle = 0;
        this.report.visible = true;
        this.tweens.add({
            targets: this.report,
            alpha: 1,
            angle: -15,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 1200,
            onComplete: () => this.report.visible = false
        });

    }

    private showBlockAnimation(): void {

        this.block.scale = 2;
        this.block.alpha = 0;
        this.block.angle = -5;
        this.block.visible = true;
        this.tweens.add({
            targets: this.block,
            scale: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 600
        });

        this.time.delayedCall(1800, () => this.block.visible = false);

    }

    private showPlusOneAnimation(): void {
        
        this.plusOne.y = this.plusOnePositionY;
        this.plusOne.scale = 0.6;
        this.plusOne.alpha = 0.4;
        this.plusOne.visible = true;
        this.tweens.add({
            targets: this.plusOne,
            y: this.plusOnePositionY - 20,
            scale: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400,
        });
        this.tweens.add({
            targets: this.plusOne,
            y: this.plusOnePositionY - 50,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.In,
            duration: 1000,
            delay: 400,
            onComplete: () => this.plusOne.visible = false
        });

    }

    private getXAxisPositionFrom(position: Phaser.Geom.Point, distance: number, isOther: boolean = false): Phaser.Geom.Point {
        let angle = this.xAxisAngle;
        if (isOther) {
            angle = this.xAxisAngleOther;
        }
        return new Phaser.Geom.Point(
            position.x + Math.cos(Phaser.Math.DegToRad(angle)) * distance,
            position.y + Math.sin(Phaser.Math.DegToRad(angle)) * distance
        );
    }

    private getYAxisPositionFrom(position: Phaser.Geom.Point, distance: number): Phaser.Geom.Point {
        return new Phaser.Geom.Point(
            position.x + Math.cos(Phaser.Math.DegToRad(this.yAxisAngle)) * distance,
            position.y + Math.sin(Phaser.Math.DegToRad(this.yAxisAngle)) * distance
        );
    }

    private addTexts(texts: any[]): number {

        let totalDelay: number = 0;

        for (let i = 0; i < texts.length; i++) {
            
            let delay = i > 0 ? 1000 : 0;
            
            const text = texts[i];
            if (text.hasOwnProperty('delay')) {
                delay += text.delay * 1000;
            }
            let isOther = false;
            if (text.hasOwnProperty('isBadGuy')) {
                isOther = true;
            }
            let isCensored = false;
            if (text.hasOwnProperty('isCensored')) {
                isOther = true;
                isCensored = true;
            }

            let message = '';
            if (text.hasOwnProperty('text')) {
                message = text.text;
            }

            this.time.delayedCall(totalDelay + delay, () => this.addTextBubble(message, isOther, isCensored));

            totalDelay += delay;

        }

        return totalDelay;

    }

    private addTextBubble(message: string, isOther: boolean = false, isCensored: boolean = false): void {

        let textColor = '#ffffff';
        if (isOther) {
            textColor = '#000000';
        }

        let bubbleAsset = 'TextBubblePlayer.png';
        if (isOther) {
            bubbleAsset = 'TextBubbleOther.png';
        }

        const textRef = new GameObjects.Text(
            this, 0, 0,
            message,
            {
                fontFamily: 'Arial',
                fontSize: 18,
                lineSpacing: 3,
                color: textColor,
                align: 'left'
            }
        ).setOrigin(0.5);
        textRef.setWordWrapWidth(240);
        textRef.angle = this.phoneAngle;

        let bubbleWidth;
        let bubbleHeight;
        let objectRef: BubbleType;
        
        if (isCensored) {

            const censoredRef = new GameObjects.Image(
                this, 0, 0,
                assetHelper.texture,
                'CensoredMessage.png'
            );
            censoredRef.setOrigin(0.5, 0.44);
            censoredRef.angle = this.phoneAngle;

            bubbleWidth = censoredRef.width;
            bubbleHeight = censoredRef.height;
            objectRef = censoredRef;

        } else {

            const paddingX = 15;
            const paddingY = 15;
            bubbleWidth = textRef.getBounds().width + paddingX * 2;
            bubbleHeight = textRef.getBounds().height + paddingY * 2;

            const bubbleRef = new GameObjects.NineSlice(
                this, 0, 0,
                assetHelper.texture,
                bubbleAsset,
                bubbleWidth,
                bubbleHeight,
                30, 30, 15, 25
            );
            bubbleRef.setOrigin(0.5, 0.41);
            bubbleRef.angle = this.phoneAngle;

            objectRef = bubbleRef;
            
        }

        this.add.existing(objectRef);
        this.add.existing(textRef);

        const position = this.getNextBubblePosition(bubbleWidth, bubbleHeight, isOther);

        const textBubble = new TextBubble(textRef, objectRef, position, isCensored);
        this.textBubbles.push(textBubble);

        this.positionTextBubbles();

    }

    private getNextBubblePosition(bubbleWidth: number, bubbleHeight: number, isOther: boolean = false): Phaser.Geom.Point {

        // increase bubblePosition by the padding plus the full height of the bubble
        this.bubblePosition = this.getYAxisPositionFrom(this.bubblePosition, -(bubbleHeight + this.bubblePadding));
        
        const distance = Phaser.Math.Distance.BetweenPoints(this.bubblePositionInit, this.bubblePosition);
        if (distance > this.maxDistance) {

            const offset = distance - this.maxDistance;
            this.textBubbles.forEach((textBubble) => {

                // find the target position
                const targetPosition = this.getYAxisPositionFrom(textBubble.position, offset);

                this.tweens.add({
                    targets: textBubble.position,
                    x: targetPosition.x,
                    y: targetPosition.y,
                    ease: Phaser.Math.Easing.Quadratic.Out,
                    duration: 100,
                    onUpdate: () => this.positionTextBubble(textBubble)
                });

                // add masks to text bubbles close to the threshold
                const maskThreshold = Phaser.Math.Distance.BetweenPoints(
                    this.phonePosition,
                    this.getYAxisPositionFrom(textBubble.position, offset + textBubble.bubble.height / 2)
                );
                if (maskThreshold > 380 && !textBubble.isMasked) {
                    textBubble.text.mask = this.textMask;
                    textBubble.bubble.mask = this.textMask;
                    textBubble.isMasked = true;
                }

            });

            // keep bubble position at max distance
            this.bubblePosition = this.getYAxisPositionFrom(this.bubblePosition, offset);

        }

        // find the center Y position for the next bubble
        const nextBubbleYPosition = this.getYAxisPositionFrom(this.bubblePosition, bubbleHeight / 2);

        // return the center position on the X axis derived from the bubble Y position
        return this.getXAxisPositionFrom(nextBubbleYPosition, 175 - bubbleWidth / 2, isOther);

    }

    private positionTextBubble(textBubble: TextBubble): void {
        textBubble.text.setPosition(textBubble.position.x, textBubble.position.y);
        textBubble.bubble.setPosition(textBubble.position.x, textBubble.position.y);
    }

    private positionTextBubbles(): void {
        this.textBubbles.forEach((textBubble) => {
            this.positionTextBubble(textBubble);
        });
    }

    private revealBadGuy(step: number): void {

        let targetScaleY: number;
        let targetAlpha: number;
        switch(step) {
            case 1:
                targetScaleY = 0.15;
                targetAlpha = 0.1;
                break;
            case 2:
                targetScaleY = 0.3;
                targetAlpha = 0.2;
                break;
            case 3:
                targetScaleY = 1.2;
                targetAlpha = 1;
                break;
            default:
                return;
        }

        this.tweens.add({
            targets: this.badGuyScreenMask,
            scaleY: targetScaleY,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 500,
            delay: 500
        });
        this.tweens.add({
            targets: this.badGuy,
            alpha: targetAlpha,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400,
            delay: 500
        });

        if (step == questionHelper.maxWrongAnswers) {

            // final bad guy attack sequence

            this.hideTexts();

            this.badGuyFocus.alpha = 0;
            this.badGuyFocus.visible = true;
            this.tweens.add({
                targets: this.badGuyFocus,
                alpha: 0.9,
                ease: Phaser.Math.Easing.Quadratic.Out,
                duration: 1800
            });

            this.time.delayedCall(3400, () => this.showAttackFace());
        }

    }

    private hideTexts(): void {

        this.textBubbles.forEach((textBubble) => {
            this.tweens.add({
                targets: [textBubble.text, textBubble.bubble],
                alpha: 0,
                ease: Phaser.Math.Easing.Quadratic.Out,
                duration: 300,
                onUpdate: () => this.positionTextBubble(textBubble)
            });
        });

    }

    private showAttackFace(): void {

        this.badGuy.setTexture(assetHelper.texture, 'BadGuyAttack.png');

        this.camera.shake(1600, 0.05);

        this.time.delayedCall(1600, () => this.transitionToResultsScreen());

    }

    private transitionToResultsScreen(): void {

        this.hideTexts();

        // fade out bad guy
        this.tweens.add({
            targets: [this.badGuy, this.badGuyBackground],
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 100
        });

        // fade bad guy focus
        this.tweens.add({
            targets: this.badGuyFocus,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400
        });

        // put phone away tween
        this.tweens.add({
            targets: this.phone,
            x: { from: this.phonePosition.x, to: this.phonePositionInit.x },
            y: { from: this.phonePosition.y, to: this.phonePositionInit.y },
            angle: { from: this.phoneAngle, to: this.phoneAngleInit },
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: questionHelper.isGameOver() ? 300 : 600,
            delay: 400
        });

        // fade out question display
        this.tweens.add({
            targets: [this.questionDisplay, this.questionBackground],
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 600
        });

        // tween out volume
        this.tweens.add({
            targets: this.backgroundMusic,
            volume: 0,
            ease: Phaser.Math.Easing.Linear,
            duration: 1500
        });

        this.time.delayedCall(1500, () => this.scene.start('Results'));

    }

}
