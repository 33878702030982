import { Scene, GameObjects } from 'phaser';

//import { assetHelper } from '../helpers/assetHelper';

import { SimpleButton } from './SimpleButton';

export class ChoiceButton extends GameObjects.Container {

    public choice: string;
    public callback: (choice: string) => void;

    public choiceText: GameObjects.Text;
    public button: SimpleButton;

    //public correctMark: GameObjects.Image;

    constructor(scene: Scene,
                x: number,
                y: number,
                text: string,
                choice: string,
                callback: (choice: string) => void) {

        super(scene, x, y);

        this.choice = choice;
        this.callback = callback;

        this.choiceText = new GameObjects.Text(
            scene, 20, 17,
            text,
            {
                fontFamily: 'Arial,Helvetica',
                fontSize: 24,
                color: '#fff',
                align: 'left'
            }
        ).setOrigin(0, 0);
        this.choiceText.setMaxLines(1);
        this.choiceText.setWordWrapWidth(762);
        
        this.button = new SimpleButton(scene, 401, 31, 'ChoiceButton', () => this.callback(this.choice));
        /*
        this.correctMark = new GameObjects.Image(scene, 780, 30, assetHelper.texture, 'CorrectMark.png');
        this.correctMark.scale = 0.5;
        this.correctMark.visible = false;
        */
        this.add(this.button);
        this.add(this.choiceText);
        //this.add(this.correctMark);

    }

    public resetButton(): void {

        this.button.resetButton();
        
    }

    public enableButton(isEnabled: boolean): void {

        this.button.enableButton(isEnabled);
        
    }

    public setText(text: string): void {

        this.choiceText.setFontSize(24);
        this.choiceText.text = text;

        let isOverflow = this.choiceText.getBounds().width > 690;
        if (isOverflow) {
            this.choiceText.setFontSize(22);
        }

        //this.correctMark.visible = false;
        
    }
    /*
    public fadeInCorrectMark(scene: Scene): void {

        this.correctMark.alpha = 0;
        this.correctMark.visible = true;
        scene.tweens.add({
            targets: this.correctMark,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 1200
        });

    }

    public fadeOutCorrectMark(scene: Scene): void {

        scene.tweens.add({
            targets: this.correctMark,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400
        });

    }
    */
}
