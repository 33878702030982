import { Scene, GameObjects } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';
import { maskHelper } from '../helpers/maskHelper';
import { questionHelper } from '../helpers/questionHelper';

import { SimpleButton } from '../gameobjects/SimpleButton';

export class Results extends Scene {
    
    camera: Phaser.Cameras.Scene2D.Camera;

    background: GameObjects.Image;
    maskRect: Phaser.Geom.Rectangle;
    maskGraphics: GameObjects.Graphics;
    backgroundMask: Phaser.Display.Masks.GeometryMask;

    overlay: GameObjects.Graphics;

    resultsText: GameObjects.Text;
    resultsTextShadow: GameObjects.Text;
    scoreMessageText: GameObjects.Text;
    scoreMessageTextShadow: GameObjects.Text;
    scoreText: GameObjects.Text;
    scoreTextShadow: GameObjects.Text;

    trophy: GameObjects.Image;
    starburst: GameObjects.Image;

    retryButton: SimpleButton;

    constructor () {
        super('Results');
    }

    init() {

        this.camera = this.cameras.main;
        this.camera.setBackgroundColor(0x000000);
        
    }

    create() {

        // background and mask

        this.maskRect = Phaser.Geom.Rectangle.Clone(maskHelper.maskActiveRect);
        this.maskGraphics = new GameObjects.Graphics(this);
        maskHelper.redrawMask(this.maskGraphics, this.maskRect);
        this.backgroundMask = new Phaser.Display.Masks.GeometryMask(this, this.maskGraphics);

        this.background = this.add.image(672, 384, assetHelper.texture, 'Background.png');
        this.background.mask = this.backgroundMask;

        // overlay

        this.overlay = new GameObjects.Graphics(this);
        this.overlay.fillStyle(0x000000, 0.5);
        this.overlay.fillRect(maskHelper.maskFullRect.x, maskHelper.maskFullRect.y, maskHelper.maskFullRect.width, maskHelper.maskFullRect.height);
        this.overlay.alpha = 0;
        this.add.existing(this.overlay);

        // results text

        const maxWidth = 960;
        
        const resultsMessage = questionHelper.isGameOver() ? questionHelper.failMessage : questionHelper.passMessage;
        const resultsTextPosition = new Phaser.Geom.Point(672, 154);
        const resultsTextStyle = {
            fontFamily: 'Bahnschrift, Arial, sans-serif',
            fontSize: 54,
            fontStyle: '700',
            lineSpacing: 3,
            align: 'center'
        };

        this.resultsText = new GameObjects.Text(
            this,
            resultsTextPosition.x,
            resultsTextPosition.y,
            resultsMessage,
            {
                ...resultsTextStyle,
                color: '#fff'
            }
        ).setOrigin(0.5, 0.5);
        this.resultsText.setMaxLines(2);
        this.resultsText.setWordWrapWidth(maxWidth);
        this.resultsText.alpha = 0;

        this.resultsTextShadow = new GameObjects.Text(
            this, resultsTextPosition.x + 3, resultsTextPosition.y + 3,
            resultsMessage,
            {
                ...resultsTextStyle,
                color: '#000'
            }
        ).setOrigin(0.5, 0.5);
        this.resultsTextShadow.setMaxLines(2);
        this.resultsTextShadow.setWordWrapWidth(maxWidth);
        this.resultsTextShadow.alpha = 0;

        this.add.existing(this.resultsTextShadow);
        this.add.existing(this.resultsText);

        const scoreMessageTextPosition = new Phaser.Geom.Point(672, 274);
        const scoreMessageTextStyle = {
            fontFamily: 'Bahnschrift, Arial, sans-serif',
            fontSize: 42,
            lineSpacing: 3,
            align: 'center'
        };

        this.scoreMessageText = new GameObjects.Text(
            this, scoreMessageTextPosition.x, scoreMessageTextPosition.y,
            questionHelper.scoreMessage,
            {
                ...scoreMessageTextStyle,
                color: '#fff'
            }
        ).setOrigin(0.5, 0.5);
        this.scoreMessageText.setMaxLines(1);
        this.scoreMessageText.setWordWrapWidth(maxWidth);
        this.scoreMessageText.alpha = 0;

        this.scoreMessageTextShadow = new GameObjects.Text(
            this, scoreMessageTextPosition.x + 3, scoreMessageTextPosition.y + 3,
            questionHelper.scoreMessage,
            {
                ...scoreMessageTextStyle,
                color: '#000'
            }
        ).setOrigin(0.5, 0.5);
        this.scoreMessageTextShadow.setMaxLines(1);
        this.scoreMessageTextShadow.setWordWrapWidth(maxWidth);
        this.scoreMessageTextShadow.alpha = 0;

        this.add.existing(this.scoreMessageTextShadow);
        this.add.existing(this.scoreMessageText);

        const scoreTextPosition = new Phaser.Geom.Point(677, 420);
        const scoreTextStyle = {
            fontFamily: 'Bahnschrift, Arial, sans-serif',
            fontSize: 100,
            fontStyle: '700',
            lineSpacing: 3,
            align: 'center'
        };

        const correct = questionHelper.correctAnswers ?? 0;
        const total = questionHelper.totalQuestions ?? 0;
        let percent = 0;
        if (correct > 0 && total > 0) {
            percent = (correct / total) * 100;
        }
        const percentText = `${Math.floor(percent)}%`;

        this.scoreText = new GameObjects.Text(
            this, scoreTextPosition.x, scoreTextPosition.y,
            percentText,
            {
                ...scoreTextStyle,
                color: '#fff'
            }
        ).setOrigin(0.5, 0.5);
        this.scoreText.setMaxLines(1);
        this.scoreText.alpha = 0;

        this.scoreTextShadow = new GameObjects.Text(
            this, scoreTextPosition.x + 3, scoreTextPosition.y + 3,
            percentText,
            {
                ...scoreTextStyle,
                color: '#000'
            }
        ).setOrigin(0.5, 0.5);
        this.scoreTextShadow.setMaxLines(1);
        this.scoreTextShadow.alpha = 0;

        this.add.existing(this.scoreTextShadow);
        this.add.existing(this.scoreText);

        // tropy

        const trophyPosition = new Phaser.Geom.Point(875, 425);

        this.trophy = new GameObjects.Image(this, trophyPosition.x, trophyPosition.y, assetHelper.texture, 'Trophy.png');
        this.trophy.angle = 5;
        this.trophy.visible = false;

        this.starburst = new GameObjects.Image(this, trophyPosition.x, trophyPosition.y - 30, assetHelper.texture, 'Starburst.png');
        this.starburst.visible = false;

        this.add.existing(this.starburst);
        this.add.existing(this.trophy);

        // menu
        
        this.retryButton = new SimpleButton(this, 672, 600, 'RetryButton', () => this.handlePlayButtonClicked());
        this.retryButton.visible = false;
        this.add.existing(this.retryButton);

        // transition away from game

        this.tweens.add({
            targets: this.overlay,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 800
        });

        this.tweens.add({
            targets: this.maskRect,
            x: { from: maskHelper.maskActiveRect.x, to: maskHelper.maskFullRect.x },
            y: { from: maskHelper.maskActiveRect.y, to: maskHelper.maskFullRect.y },
            width: { from: maskHelper.maskActiveRect.width, to: maskHelper.maskFullRect.width },
            height: { from: maskHelper.maskActiveRect.height, to: maskHelper.maskFullRect.height },
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 800,
            onUpdate: () => this.handleTransitionUpdate()
        });

        // fade in results

        this.time.delayedCall(1200, () => this.fadeInResults());

        if (!questionHelper.isGameOver()) {
            this.time.delayedCall(1200, () => this.playTropyAnimation());
        }

        this.time.delayedCall(2600, () => this.retryButton.visible = true);
        
    }

    update() {

        this.starburst.angle += 0.3;

    }

    fadeInResults(): void {

        this.tweens.add({
            targets: [
                this.resultsText, this.resultsTextShadow,
                this.scoreMessageText, this.scoreMessageTextShadow,
                this.scoreText, this.scoreTextShadow],
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 900
        });

    }

    fadeOutResults(): void {

        this.tweens.add({
            targets: [
                this.resultsText, this.resultsTextShadow,
                this.scoreMessageText, this.scoreMessageTextShadow,
                this.scoreText, this.scoreTextShadow,
                this.trophy, this.starburst
            ],
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 400
        });

    }

    playTropyAnimation(): void {
        
        this.trophy.scale = 0.4;
        this.trophy.alpha = 0;
        this.trophy.visible = true;
        this.tweens.add({
            targets: this.trophy,
            scale: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 900
        });

        this.starburst.scale = 0.6;
        this.starburst.alpha = 0;
        this.starburst.visible = true;
        this.tweens.add({
            targets: this.starburst,
            scale: 1,
            alpha: 0.9,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 900
        });
        this.tweens.add({
            targets: this.starburst,
            alpha: 0.6,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 900,
            delay: 900
        });

    }

    handlePlayButtonClicked(): void {

        this.retryButton.visible = false;

        this.fadeOutResults();

        const transitionDuration: number = 800;
        const transitionDelay: number = 600;

        this.tweens.add({
            targets: this.overlay,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: transitionDuration,
            delay: transitionDelay
        });

        this.tweens.add({
            targets: this.maskRect,
            x: { from: maskHelper.maskFullRect.x, to: maskHelper.maskActiveRect.x },
            y: { from: maskHelper.maskFullRect.y, to: maskHelper.maskActiveRect.y },
            width: { from: maskHelper.maskFullRect.width, to: maskHelper.maskActiveRect.width },
            height: { from: maskHelper.maskFullRect.height, to: maskHelper.maskActiveRect.height },
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: transitionDuration,
            delay: transitionDelay,
            onUpdate: () => this.handleTransitionUpdate(),
            onComplete: () => this.handleTransitionComplete()
        });

    }

    handleTransitionUpdate(): void {

        maskHelper.redrawMask(this.maskGraphics, this.maskRect);

    }

    handleTransitionComplete(): void {

        this.scene.start('TextingGame', { scenario: 'scenario_1' });

    }

}
