import { Scene, GameObjects } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';

export class SimpleButton extends GameObjects.Sprite {

    public callback: () => void;

    public upFrame: string;
    public overFrame: string;
    public downFrame: string;
    public disabledFrame: string;

    public isEnabled: boolean;
    public isHovered: boolean;
    public isClicked: boolean;

    constructor(scene: Scene,
                x: number,
                y: number,
                buttonName: string,
                callback: () => void) {

        super(scene, x, y, assetHelper.texture, `${buttonName}0001.png`);

        this.callback = callback;

        this.upFrame = `${buttonName}0001.png`;
        this.overFrame = `${buttonName}0002.png`;
        this.downFrame = `${buttonName}0003.png`;
        this.disabledFrame = `${buttonName}0004.png`;
        
        this.enableButton(true);

    }

    public resetButton(): void {

        this.setTexture(assetHelper.texture, this.upFrame);

    }

    public enableButton(isEnabled: boolean): void {

        this.isEnabled = isEnabled;

        if (this.isEnabled) {

            this.setInteractive(/*{ useHandCursor: true }*/)
                .on('pointerover', () => { this.setTexture(assetHelper.texture, this.overFrame); this.handleOver(); })
                .on('pointerout', () => { this.setTexture(assetHelper.texture, this.upFrame); this.handleOut(); })
                .on('pointerdown', () => { this.setTexture(assetHelper.texture, this.downFrame); this.handleDown(); })
                .on('pointerup', () => { this.setTexture(assetHelper.texture, this.overFrame); this.handleUp(); });

            this.setTexture(assetHelper.texture, this.upFrame);

        } else {

            this.disableInteractive();

            this.isHovered = false;
            this.isClicked = false;

            this.setTexture(assetHelper.texture, this.disabledFrame);
        }

    }

    private handleOver() {

        this.isHovered = true;

    }

    private handleOut() {

        this.isHovered = false;
        this.isClicked = false;

    }

    private handleDown() {

        this.isClicked = true;

    }

    private handleUp() {

        if (this.isEnabled && this.isClicked) this.callback();

    }    

}
