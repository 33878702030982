import { GameObjects } from 'phaser';

export type BubbleType = GameObjects.NineSlice | GameObjects.Image;

export class TextBubble {

    public text: GameObjects.Text;
    public bubble: BubbleType;
    public position: Phaser.Geom.Point;
    public isCensored: boolean;

    public isMasked: boolean;

    constructor(text: GameObjects.Text,
                bubble: BubbleType,
                position: Phaser.Geom.Point,
                isCensored: boolean) {

        this.text = text;
        this.bubble = bubble;
        this.position = position;
        this.isCensored = isCensored;

        this.isMasked = false;

    }

}
