import { Scene } from 'phaser';

class AssetHelper {

    public texture: string;
    public frameRate: number;

    constructor() {

        this.texture = 'sprites';
        this.frameRate = 30;
        
    }

    public createAnimations(scene: Scene): void {

        this.createAnimation(scene, 'CorrectAnimation', 10, 1, 0);
        this.createAnimation(scene, 'IncorrectAnimation', 10, 1, 0);
        
    }

    private createAnimation(scene: Scene, key: string, end: number, start: number = 1, repeat: number = -1): void {
        scene.anims.create({ key: key, frames: scene.anims.generateFrameNames(this.texture, {
            start: start, end: end, zeroPad: 4,
            prefix: key, suffix: '.png'
        }), frameRate: this.frameRate, repeat: repeat });
    }

}

export var assetHelper = new AssetHelper();
