import { Scene } from 'phaser';

class QuestionHelper {

    public scenario: any;
    public questions: any[];

    public passMessage: string;
    public failMessage: string;
    public scoreMessage: string;

    public question: string;
    public choices: any;
    public correctChoice: string;
    public correctAnimations: string[];
    public preTexts: any[];
    public choiceTexts: any;

    public questionIndex: number;
    public totalQuestions: number;
    public correctAnswers: number;
    public wrongAnswers: number;
    public maxWrongAnswers: number;

    constructor() {

    }

    public loadData(scene: Scene, scenarioName: string): void {

        const data = scene.cache.json.get('questionData');

        this.scenario = data.scenarios.find((e: any) => e.name == scenarioName);
        this.questions = this.scenario.questions;
        //console.log(this.questions);

        this.passMessage = this.scenario.passMessage;
        this.failMessage = this.scenario.failMessage;
        this.scoreMessage = this.scenario.scoreMessage;

        this.questionIndex = 0;
        this.totalQuestions = this.questions.length;
        this.correctAnswers = 0;
        this.wrongAnswers = 0;
        this.maxWrongAnswers = 3;

    }

    public loadNextQuestion(): boolean {

        if (this.questionIndex >= this.questions.length) {
            return false;
        }

        const question = this.questions[this.questionIndex];

        this.question = question.question;
        this.choices = question.choices;
        this.correctChoice = question.correctChoice;
        this.correctAnimations = question.hasOwnProperty('correctAnimations') ? question.correctAnimations : [];
        this.preTexts = question.hasOwnProperty('preTexts') ? question.preTexts : [];
        this.choiceTexts = question.hasOwnProperty('choiceTexts') ? question.choiceTexts : null;

        this.questionIndex++;

        return true;

    }

    public checkQuestion(choice: string): boolean {

        if (choice == this.correctChoice) {
            this.correctAnswers++;
            return true;
        }

        this.wrongAnswers++;
        return false;

    }

    public isGameOver(): boolean {
        return this.wrongAnswers >= this.maxWrongAnswers;
    }

}

export var questionHelper = new QuestionHelper();
