import { Scene, GameObjects } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';

export class MuteButton extends GameObjects.Sprite {

    public callback: (isMuted: boolean) => void;

    public defaultFrame: string;
    public mutedFrame: string;

    public isMuted: boolean;

    constructor(scene: Scene,
                x: number,
                y: number,
                callback: (isMuted: boolean) => void) {

        super(scene, x, y, assetHelper.texture, 'Mute_0.png');

        this.callback = callback;

        this.defaultFrame = 'Mute_0.png';
        this.mutedFrame = 'Mute_1.png';
        
        this.setInteractive().on('pointerdown', () => this.handleDown());

        this.resetButton();

    }

    public resetButton(): void {

        this.isMuted = false;

        this.setTexture(assetHelper.texture, this.defaultFrame);

    }

    private handleDown() {

        this.isMuted = !this.isMuted;

        if (this.isMuted) {
            this.setTexture(assetHelper.texture, this.mutedFrame);
        } else {
            this.setTexture(assetHelper.texture, this.defaultFrame);
        }

        this.callback(this.isMuted);

    }

}
