import { GameObjects } from 'phaser';

class MaskHelper {

    public canvas: HTMLCanvasElement;

    public maskFullRect: Phaser.Geom.Rectangle;
    public maskActiveRect: Phaser.Geom.Rectangle;

    constructor() {

    }

    public init(canvas: HTMLCanvasElement): void {

        this.canvas = canvas;

        const backgroundWidth: number = 1344;
        const backgroundHeight: number = 768;

        const activeBorderSize: number = 20;
        const maskX: number = (canvas.width - backgroundWidth) / 2;

        this.maskFullRect = new Phaser.Geom.Rectangle(maskX, 0, backgroundWidth, backgroundHeight);
        this.maskActiveRect = new Phaser.Geom.Rectangle(
            this.maskFullRect.x + activeBorderSize,
            this.maskFullRect.y + activeBorderSize,
            this.maskFullRect.width - activeBorderSize * 2,
            this.maskFullRect.height - activeBorderSize * 2
        );

    }

    public redrawMask(mask: GameObjects.Graphics, rect: Phaser.Geom.Rectangle): void {

        mask.clear();
        mask.fillStyle(0x000000, 1);
        mask.fillRect(rect.x, rect.y, rect.width, rect.height);

    }

}

export var maskHelper = new MaskHelper();
